var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('manufacturingOperations.Withdrawals')))]),_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/manufacturingWithdrawals/create?manufacturing_operation_id' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('manufacturingOperations.addition'))+" ")])],1),_c('indexTable',{attrs:{"searchText":_vm.$t('manufacturingWithdrawals.SearchForAnOperation'),"emptyTableText":_vm.$t('manufacturingWithdrawals.ThereAreNoWithdrawalsWithManufacturingProcesses'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"filter":{manufacturing_operation_id: _vm.$route.params.id},"withoutCard":true,"cloumns":[
        {column: 'code',    title: _vm.$t('manufacturingWithdrawals.operation') ,type: 'mainLink' , sort: true },
        {column: 'manufacturing_operation_id',    title: _vm.$t('manufacturingWithdrawals.TheNameOfTheManufacturingProcess') ,type: 'linkInvoice', to:'manufacturingOperation', sort: true, link: true},
        {column: 'store_id',    title: _vm.$t('manufacturingWithdrawals.storeName'), type: 'link', to:'store', sort: true, link: true},
        {column: 'date',    title: _vm.$t('manufacturingWithdrawals.theDateOfTheOperation') ,type: 'text' , sort: true },
        {column: 'price',    title: _vm.$t('manufacturingWithdrawals.price') ,type: 'text' , sort: true },
        {column: 'description',    title: _vm.$t('manufacturingWithdrawals.thatAbout') ,type: 'text' , sort: true },
        {column: 'options', title: _vm.$t('manufacturingWithdrawals.settings'), type: 'options', options: [
            {name: 'show'},
            {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.projects_withdrawals_edit},
            {name: 'printprojectsWithdrawal',   role: true},
            {name: 'delete', role: _vm.$user.admin || _vm.$user.role.projects_withdrawals_delete} ]}
      ],"deleteText":{
        attention: _vm.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$t('allerts.close'),
        confirm: _vm.$t('allerts.confirm'),
        loading: _vm.$t('allerts.loading'),
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }